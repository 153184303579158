<template>
    <div id="Orders" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="notifications_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Enviar Notificación
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12" v-if="!loading && !fetch_error && !empty_search">
            <ns-table :data="notifications_data" 
                :allow_headers="notifications_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:send_date="data">
                    {{ 
                        data.item.send_date == null ? 'En proceso de envío' : 
                            $informativeDate(data.item.send_date)
                    }}
                </template>
                <template v-slot:picture="data">
                    <div class="flex-center">
                        <div style="max-width: 60px;">
                            <img :src="data.value" 
                                class="img-fluid">
                        </div>
                    </div>
                </template>
                <template v-slot:schedule_date="data">
                    {{ $informativeDate(data.item.schedule_date) }}
                </template>
                <template v-slot:active="data">
                    {{ data.item.active ? 'Activo' : 'Inactivo' }}
                </template>
                <template v-slot:type_notification="data">
                    {{ data.item.type_notification == 'push_notification' ? 'Notificación push' : data.item.type_notification == 'email' ? 'Correo electrónico' : data.item.type_notification == 'whatsapp' ? 'whatsapp' : data.item.type_notification == 'sms' ? 'SMS' : data.item.type_notification == 'voice' ? 'Voz' : '' }}
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                            class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 200px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">
                                <!-- <button @click.stop="selectTableItem('notifications', data.item.id_notification)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Recordatorios
                                </button> -->

                                <button @click.stop="selectTableItem('detail', data.item.id_notification)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Detalles
                                </button>

                                <button @click.stop="selectTableItem('edit', data.item.id_notification)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id_notification)" 
                                    v-if="data.item.active"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id_notification)" 
                                    v-if="!data.item.active"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>

                                <button @click.stop="selectTableItem('send', data.item.id_notification)" 
                                    v-if="data.item.send_date == null"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Enviar Ahora
                                </button>
                                
                            </div>
                        </div>  
                    </div>
                </template>
            </ns-table>
        </div>

        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>


                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />

                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="notifications_modal" bg_color="white" max_width="680"
            overlay_close @onClose="notifications_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Título:" 
                        theme="light"
                        v-model="title"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.title">
                        {{ errors.title }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-select label="Tipo" 
                        theme="light"
                        :options="[{id:0, label: 'Seleccionar..'}, {id: 'todos', label: 'Todos'},{id: 'miembros', label: 'Miembros'},{id: 'clientes', label: 'Clientes'}]"
                        v-model="type_notification"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.type_notification">
                        {{ errors.type_notification }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Imagen Destacada
                    </label>
                    <ns-dropfile 
                        _id="image_1"
                        @charged="checkFile"
                        style="height: 255px;"
                        variant="success">
                    </ns-dropfile>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Preview
                    </label>
                    <div style="max-width:300px;">
                        <img class="img-fluid"
                        :src="preview_image" />
                    </div>
                </div>

                <div class="col-12 pr-4 pl-4 mt-3">
                    <label class="text-dark">
                        Mensaje:
                    </label>
                    <textarea class="form-control bg-light  w-100"
                        rows="5"
                        style="border: none; border-radius: 15px;"
                        v-model="message">
                    </textarea>
                    <span class="text-danger" v-if="errors.message">
                        {{ errors.message }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input 
                        label="Fecha programada" 
                        theme="light"
                        v-model="schedule"
                        type="date"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.schedule">
                        {{ errors.schedule }}
                    </span>
                </div>

                <div class="col-8 col-md-6 mt-4 montserrat-bold">
                    <div class="row p-0 justify-content-end align-items-center h-100">
                        <div class="col-12  d-flex justify-content-between p-0 px-3">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="detail_modal" bg_color="white" max_width="680"
            overlay_close @onClose="detail_modal = false">
            <div class="row w-100 justify-content-between montserrat-regular text-dark">

                <div class="col-12" style="font-size: 22px;">
                    <p class="text-black montserrat-semibold">
                        Detalles
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Título:</strong>  
                       <span class="d-block">
                            {{ notification.title }}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Mesanje:</strong>  
                       <span class="d-block">
                            {{ notification.message}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Sección:</strong>  
                       <span class="d-block">
                            {{ notification.section }}
                       </span>
                    </p>
                </div>

                <div class="col-6 ">
                    <p class="underline_p">
                       <strong>Fecha de envio:</strong>  
                       <span class="d-block">
                            {{ 
                                notification.send_date == null ? 'En proceso de envío' : 
                                    $informativeDate(notification.send_date) 
                            }}
                       </span>
                    </p>
                </div>

                <div class="col-6">
                    <p class="underline_p">
                       <strong>Fecha programa:</strong>  
                       <span class="d-block">
                            {{ new Date(notification.schedule_date).toLocaleDateString() }}
                       </span>
                    </p>
                </div>



                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="detail_modal = false"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cerrar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>

    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import star from 'ASSETS/icons/star.png'
    import star_fill from 'ASSETS/icons/star_fill.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                star,
                star_fill,
                notifications_modal: false,
                delete_modal: false,
                active_modal: false,
                detail_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                selected_id: false,
                notifications_headers: [
                    {key: 'title', label: 'Título' },
                    {key: 'picture', label: 'Imagen' },
                    {key: 'type_notification', label: 'Tipo' },
                    {key: 'message', label: 'Mensaje' },
                    {key: 'send_date', label: 'Fecha de envio' },
                    {key: 'schedule_date', label: 'Fecha programada' },
                    {key: 'active', label: 'Estatus' },
                    {key: 'actions', label: 'Acciones' }
                ],
                notifications_data: [],
                title: null,
                message: null,
                picture: null,
                preview_image: null,
                schedule: null,
                type_notification: '0',
                errors: {
                    title: null,
                    message: null,
                    picture: null,
                    schedule: null,
                    type_notification: null
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.title = null
                this.message = null
                this.preview_image = null
                this.schedule = null
                this.picture = null
                this.type_notification = '0'
                this.delete_modal = false
                this.active_modal = false
                this.detail_modal = false
                this.selected_id = false

                this.notifications_modal = false
            },
            checkFile(file){
                this.preview_image = file.url;
                this.picture = file.file.name;
                
            },
            async selectTableItem(type, id) {
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'detail':
                        await this.$store.dispatch('notifications/view', { id_notification: id })
                        this.detail_modal = true
                        break;
                    case 'edit':
                        this.selected_id = id
                        await this.$store.dispatch('notifications/view', { id_notification: this.selected_id })
                        this.title = this.notification.title
                        this.message = this.notification.message
                        this.type_notification = this.notification.type_notification
                        this.picture = this.notification.preview_image
                        this.schedule = this.formatDate(this.notification.schedule_date)
                        this.notifications_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    case 'send':
                        this.selected_id = id
                        await this.$store.dispatch('notifications/send', { id_notification: this.selected_id })
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('notifications/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('notifications/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('notifications/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.notifications === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.notifications_data = this.notifications.map( notification => {
                    return {
                        ...notification,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            // posts CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.title) || _.isEmpty(this.title)) {
                    this.errors.title = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.type_notification) || _.isEmpty(this.type_notification) || this.type_notification == '0') {
                    this.errors.type_notification = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.message) || _.isEmpty(this.message)) {
                    this.errors.message= '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.schedule) || _.isEmpty(this.schedule)) {
                    this.errors.schedule = '*Campo requerido'
                    complete = false
                }

                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                title: this.title,
                                message: this.message,
                                picture: this.preview_image,
                                type_notification: this.type_notification,
                                schedule_date: this.schedule
                            },
                            query: {
                                id_notification: this.selected_id
                            }
                        }

                        await this.$store.dispatch('notifications/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {
                        let payload = {
                            title: this.title,
                            message: this.message,
                            picture: this.preview_image,
                            type_notification: this.type_notification,
                            schedule_date: this.schedule
                        }
                        
                        await this.$store.dispatch('notifications/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('notifications/delete', { id_notification: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },

            async activateElement() {
                await this.$store.dispatch('notifications/delete', { id_notification: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        computed: {
            ...mapGetters({
                notification: 'notifications/getNotification',
                notifications: 'notifications/getNotifications',
                last_page: 'notifications/getLastPage',
                total_pages: 'notifications/getTotalPages'
            })
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'NOTIFICACIONES')
                this.$store.commit('setIndex', 6)

                //list of notifications
                await this.$store.dispatch('notifications/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                if(this.notifications) {
                    this.notifications_data = this.notifications.map( notification => {
                        return {
                            ...notification,
                            actions: '-',
                            open: false
                        }
                    })
                }
                

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>